import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const CompliancePage = () => {
  return (
    <Layout>
      <Seo  title='Sara Fashionwear'/>
      <div className="px-4 sm:px-6 lg:px-10 pt-12">
      <p className="text-lg font-bold">Privacy Policy</p>
      <p className="pb-2">Last updated: Jan 27, 2022</p>
      <p className="pb-2">Sara Fashionwear Ltd. is committed to protecting your privacy and be transparent with all of our online visitors. This Statement of Privacy applies to the company website, http://www.sarafashionwear.com and governs its data collection and usage. The document also tells You about Your privacy rights and how the law protects You. By using this website, you consent to the data practices described in this statement, regardless of the device used.</p>
      <p className="pb-2">Interpretation and Definitions</p>
      <p className="pb-2">Interpretation</p>
      <p className="pb-2">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
      <p className="pb-2">Definitions</p>
      <p className="pb-2">For the purposes of these Terms and Conditions:</p>
      <p className="pb-2">For the purposes of this Privacy Policy:</p>
      <p className="pb-2">Company refers to Sara Fashionwear Ltd., Montribari Road, South Salna, Salna Bazar.</p>
      <p className="pb-2">Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
      <p className="pb-2">Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
      <p className="pb-2">Personal Data is any information that relates to an identified or identifiable individual.</p>
      <p className="pb-2">Service refers to the Website</p>
      <p className="pb-2">Website refers to Sara Fashionwear, accessible from http://www.sarafashionwear.com</p>
      <p className="pb-2">You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
      <p className="pb-2">1. Personal data that this website collects provided by you and how we use it</p>
      <p className="pb-2">Unless stated otherwise in detail in the relevant sections of the Website, Personal Data generated from the use of our Website is processed as follows:</p>
      <p className="pb-2">Contact via electronic mail (e-mail)</p>
      <p className="pb-2">Should you choose to communicate with us via an email or through our contact form on the “Contact” page we invite you to provide your name, as well your mailing address. Please note that your name and mailing address is the only data we collect required for processing Your request. Without this information we are unable to answer your message and address it to you personally. The legal basis for processing Personal Data for the purposes set out in this Section 2 is art. 6(1)(b) of GDPR as the processing is necessary for the response to requests from the interested party.</p>
      <p className="pb-2">2. Retention of Your Personal Data</p>
      <p className="pb-2">If you submit your contact details via the preferred email message, the only personal data will be stored is your name, phone number, and email address we receive from you. We may share Your information only with Our business partners to offer You certain products that are outside of our product portfolio to meet your business needs. Under no other circumstance will the personal information You provide Us be shared with third parties and will be securely stored by Sara Fashionwear Ltd. with access only by authorized personnel. This is currently the only occasion where personal data will be stored by this Website. We utilize state-of-the-art technology to store your data. The following safeguards are used, for example, to protect your personal data from misuse or any form of unauthorized processing:
Access to personal data is restricted to a limited number of authorized persons for the stated purposes.
The IT systems used for processing data are technically isolated from other systems to prevent unauthorized access and hacking.
Access to these IT systems is constantly monitored to detect and prevent misuse in the early stages.
We will keep your personal information only for as long as it is relevant and useful for the intended purpose for which it was originally collected, or as required by law.
</p>
      <p className="pb-2">3. Internal Transfer of Your Personal Data</p>
      <p className="pb-2">Your information, including Personal Data, is processed at the Company's operating offices in Bangladesh, Italy and Germany. This means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer. The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
      <p className="pb-2">4. Cookies</p>
      <p className="pb-2">This Website does not use cookies for data collection.</p>
      <p className="pb-2">5. Links to Other Websites</p>
      <p className="pb-2">Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
      <p className="pb-2">6. Data Breaches</p>
      <p className="pb-2">We will report any unlawful data breach of your data within 72 hours of the breach if it is apparent that personal data stored in an identifiable manner has been stolen.</p>
      <p className="pb-2">7. Your rights as a data subject with respect to your personal data</p>
      <p className="pb-2">Under the General Data Protection Regulation [Articles 15-21], you have a number of important rights free of charge. In summary, those include rights to:</p>
      <p className="pb-2">Right of access: You have the right to be aware and verify the legitimate nature of the processing. So, you have the right to access your personal data and receive additional information about how we process it.</p>
      <p className="pb-2">Right to rectification: You have the right to study, correct, update or modify your personal data by contacting Sara Fashionwear Ltd. at the info@sarafashionwear.com.</p>
      <p className="pb-2">Right to erasure (Right to be forgotten): You have the right to request deletion of your personal data when we process it on your consent or in order to protect our legitimate interests. In all other cases (such as, where there is a contract, obligation to process personal data legally required, or public interest), this right is subject to specific restrictions or shall not exist, as the case may be.</p>
      <p className="pb-2">Right to restriction of processing: You have the right to request a restriction of the processing of your personal data in the following cases: (a) when the accuracy of the personal data is contested and until the accuracy is verified (b) when you oppose the deletion of your personal data and request the restriction of their use instead, c) when personal data are not needed for processing purposes, they are however required for the establishment, exercise, or defense of legal claims, and (d) when you object to the processing and the decision on your objection to processing is pending.</p>
      <p className="pb-2">Right to object to processing: You have the right to object at any time to the processing of your personal data where, as described above, the processing is based on the legitimate interests we pursue as data controllers, as well as, for the purposes of direct marketing and consumer profiling, if applicable.</p>
      <p className="pb-2">Right to data portability: You have the right to receive your personal data free of charge in a format that allows you to access, use, and edit them with commonly used editing methods. You also have the right to ask us, in case it is technically feasible, to transmit the data directly to another controller. Your right to do so exists for the data you have provided to us and is processed by automated means based on your consent or for the execution of a relevant contract.</p>
      <p className="pb-2">Right to withdraw your consent: In cases where processing is based on your consent, you have the right to withdraw it without affecting the lawfulness of processing based on consent prior to its withdrawal.</p>
      <p className="pb-2">If you would like to exercise any of those rights, please:</p>
      <p className="pb-2">contact us using our Contact details below, let us have enough information to identify you,
let us have proof of your identity and address, and
let us know the information to which your request relates.</p>
      <p className="pb-2">8. Changes to this Privacy Policy</p>
      <p className="pb-2">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. We will keep the date at the top of this Privacy Policy updated. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      <p className="pb-2">Contact Us</p>
      <p className="pb-2">If you have any questions about these Terms and Conditions, You can contact us: By email: info@sarafashionwear.com</p>
      </div>
    </Layout>
  )}

export default CompliancePage